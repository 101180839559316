import router from "../router/index"

type useUrlParams = {
  href: string
}

const resolveUrl = (url: string): string => {
  if (url.startsWith("http")) {
    return url
  } else {
    return window.location.protocol + "//" + url
  }
}

const useUrl = (routeName: string, params?: Record<string, any>): useUrlParams => {
  const route =
    window.location.host +
    router.resolve({
      name: routeName,
      params,
    }).href

  return {
    href: route,
  }
}

export { useUrl, resolveUrl }
