import { COUNTRIES, ICountry } from "./../constants/data"

type UseCountry = {
  getCountryByName: (name: string) => ICountry | undefined
  getCountryFlag: (name: string) => string | undefined
}

export const useCountry = (): UseCountry => {
  function getCountryByName(name: string) {
    return COUNTRIES.find((countries) => countries.en === name)
  }

  function getCountryFlag(name: string) {
    if (!name) return
    return getCountryByName(name)?.flag
  }

  return {
    getCountryByName,
    getCountryFlag,
  }
}
