
import { Vue, Component, Prop } from "vue-property-decorator"
import Loader from "../widgets/common/Loader.vue"

@Component({
  components: {
    Loader,
  },
})
export default class AppDetailPage extends Vue {
  @Prop({ required: false })
  readonly title?: string

  @Prop({ required: false, default: true })
  readonly back?: boolean

  @Prop({ required: false, default: "" })
  readonly backRoute?: string

  @Prop({ required: false, default: "" })
  readonly backRouteName?: string

  @Prop({ required: false, default: false })
  readonly isLoading?: boolean
}
