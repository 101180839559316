import { Icons } from "@/types"

export const businessCategories = [
  {
    text: "Investment Fund",
    value: "investment_fund",
  },
  {
    text: "Foundation/NGO",
    value: "ngo",
  },
  {
    text: "Business",
    value: "development_institution",
  },
  {
    text: "Multinational corporate",
    value: "multinational_corporate",
  },
  {
    text: "Local corporate",
    value: "local_corporate",
  },
  {
    text: "SME",
    value: "sme",
  },
  {
    text: "Individual",
    value: "individual",
  },
]

export const industries = [
  {
    text: "Financial services",
    value: "financial_services",
  },
  {
    text: "Consumer goods",
    value: "customer_goods",
  },
  {
    text: "Agriculture",
    value: "agriculture",
  },
  {
    text: "Pharmacueticals and Biotech",
    value: "pharmacueticals_and_biotech",
  },
  {
    text: "Travel and hospitality",
    value: "travel_and_hospitality",
  },
  {
    text: "Retail",
    value: "retail",
  },
  {
    text: "Manufacturing",
    value: "manufacturing",
  },
  {
    text: "Entertainment and media",
    value: "entertainment_and_media",
  },
  {
    text: "Services",
    value: "services",
  },
  {
    text: "Education",
    value: "education",
  },
  {
    text: "Transportation",
    value: "transportation",
  },
  {
    text: "Telecommunication",
    value: "telecommunication",
  },
]

export const staffCount = [
  {
    text: "1-10 people",
    value: "one_to_ten",
  },
  {
    text: "11-50 people",
    value: "eleven_to_fifty",
  },
  {
    text: "More than 50 people",
    value: "more_than_fifty",
  },
]

export const proposedStartDates = [
  {
    text: "2-4 weeks time",
    value: "one_to_four_weeks",
  },
  {
    text: "2-3 months time",
    value: "two_to_three_months",
  },
  {
    text: "More than 3 months",
    value: "more_than_three_months",
  },
]

export const projectSpecifications = [
  {
    text: "I have a clear idea of what needs to be done",
    value: "clear_idea",
  },
  {
    text: "I have an idea of what needs to be done, but could use some help",
    value: "need_help",
  },
  {
    text: "I need help with building the details",
    value: "absolutely_need_help",
  },
]

export const remoteWorkOptions = [
  {
    text: "Yes",
    value: "yes",
  },
  {
    text: "No, I need people on ground",
    value: "no",
  },
  {
    text: "Not sure",
    value: "not_sure",
  },
]

export const icons: Icons = {
  "expand-operations": {
    icon: "status-up",
    backgroundColor: "#FFEBEB",
  },
  "organize-corporate-event": {
    icon: "buliding",
    backgroundColor: "#EBF8F3",
  },

  "due-diligence": {
    icon: "user-tick",
    backgroundColor: "#FFFAEE",
  },
  "execute-business-plan": {
    icon: "lamp-charge",
    backgroundColor: "#FFFAEE",
  },
  "improve-operations": {
    icon: "verify",
    backgroundColor: "#FFEBEB",
  },
  default: {
    icon: "presention-chart",
    backgroundColor: "#FF000814",
  },
  "pitch-deck-document": {
    icon: "presention-chart",
    backgroundColor: "#FFEBEB",
  },
  "support-operational-activities": {
    icon: "data",
    backgroundColor: "#EBF8F3",
  },
  "subject-matter-expertise": {
    icon: "cup",
    backgroundColor: "#F0F5FD",
  },
  "strategy-plan": {
    icon: "ranking",
    backgroundColor: "#F0F5FD",
  },
  "technical-assistance": {
    icon: "people",
    backgroundColor: "#FFEBEB",
  },
}
